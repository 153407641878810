






























































































































import VSelectTimezone from '@/components/BaseComponents/VSelect/VSelectTimezone.vue'
import ModalConfirmTimezone from '@/components/integration/ModalConfirmTimezone.vue'
import ServicePages from '@/components/integration/ServicePages.vue'
import { TIMEZONES } from '@/constants/Timezones'
import type { Timezone } from '@/constants/Timezones'
import Vue from 'vue'
import IntegrationsAdServiceConnect from '@/components/integration/IntegrationsAdServiceConnect.vue'
import {
  FACEBOOK_SERVICE,
  GOOGLE_SERVICE,
  LINKEDIN_SERVICE,
  SLACK_SERVICE,
  SNAPCHAT_SERVICE,
  TIKTOK_SERVICE,
  TWITTER_SERVICE
} from '@/constants/FbAutomatedRule'
import { mapActions, mapGetters } from 'vuex'
import ModalConfirmDelete from '@/components/BaseComponents/BaseModalDeleteConfirm/ModalConfirmDelete.vue'
import { ERROR_CODES, DEFAULT_ERROR_CODE_MSG } from '@/constants/AdAccount'
import scrollHelper from '@/utils/scrollHelper'
import IListIntegrationAccount from '@/models/Integrations/IListIntegrationAccount'

const SERVICES_LOGIN_URL_KEYS_MAP = {
  [FACEBOOK_SERVICE]: 'fb_login_url',
  [SNAPCHAT_SERVICE]: 'snap_login_url',
  [TIKTOK_SERVICE]: 'tiktok_login_url',
  [LINKEDIN_SERVICE]: 'linkedin_login_url',
  [SLACK_SERVICE]: 'slack_login_url',
  [GOOGLE_SERVICE]: 'google_login_url',
  [TWITTER_SERVICE]: 'twitter_login_url'
} as const

const SERVICES_SCROLL_KEYS_MAP = {
  [FACEBOOK_SERVICE]: 'facebook_title',
  [SNAPCHAT_SERVICE]: 'snapchat_title',
  [TIKTOK_SERVICE]: 'tiktok_title',
  [LINKEDIN_SERVICE]: 'linkedin_title',
  [SLACK_SERVICE]: 'slack_title',
  [GOOGLE_SERVICE]: 'google_title',
  [TWITTER_SERVICE]: 'twitter_title'
}

type ModalConfirmDeleteText = {
  titleCreator: (adAccountName?: string) => string,
  text: Array<string>
}

const SERVICES_MODAL_CONFIRM_DELETE_TEXT: Record<'default', ModalConfirmDeleteText> & Record<string, ModalConfirmDeleteText | undefined> = {
  slack: {
    titleCreator: () => 'Confirm deletion',
    text: [
      'You will not receive notifications to Slack channels inside this workspace. Please confirm you want to remove it.'
    ]
  },
  default: {
    titleCreator: (adAccountName: string) => `Are you sure you want to delete ${adAccountName}?`,
    text: [
      '<strong>Automated rules</strong> created for this ad account will be <strong>turned off.</strong>',
      '<strong>Ad campaigns</strong> created for this ad account on AutomatedRules will be <strong>removed</strong> from the AutomatedRules interface.'
    ]
  }
}

export default Vue.extend({
  name: 'IntegrationsStateUserSettings',
  components: {
    ModalConfirmTimezone,
    VSelectTimezone,
    ServicePages,
    IntegrationsAdServiceConnect
  },
  props: {
    auto_connect: {
      type: Boolean,
      default: false
    },
    service: {
      type: String,
      default: null
    },
    error_code: {
      default: null,
      type: String,
      required: false
    },
    scroll_to: {
      default: null,
      type: String,
      required: false
    },
    success: {
      default: null
    }
  },
  data () {
    return {
      servicePagesKey: 0,
      modalConfirmDeleteTitle: '',
      modalConfirmDeleteText: [],
      adAccountId: null,
      del_service: null, // избавиться от этой переменной
      adAccountName: '',
      newTimezone: null as Timezone | null,
      newTimezoneUser: null,
      newTimezoneService: '',
      timezoneConfirmDisabled: false
    }
  },

  computed: {
    ...mapGetters('integrations', [
      'getFacebook', 'getSlack', 'getSnapchat', 'getTiktok', 'getLinkedin', 'getGoogle', 'getTwitter'
    ]),
    ...mapGetters('notifications', ['getNotifications']),
    ...mapGetters('profile', ['currentUser']),
    ...mapGetters(['get_width', 'get_sidebar_views']),
    ...mapGetters('adService', ['getServiceTitle']),
    slack_visible (): boolean {
      return String(process.env.VUE_APP_USER_IDS_WITH_ENABLED_SLACK_INTEGRATION as string)
        .split(' ')
        .includes(this.currentUser.id)
    },
    slackRedirectDisabled (): boolean {
      return this.getSlack.data?.length > 0
    }
  },

  mounted: function (): void {
    this.service && this.setService(this.service)
    Promise.all([
      this.getProfile(),
      this.load_ad_accounts({ service: SLACK_SERVICE }) // костыль для проверки слака
    ]).then(() => {
      if (this.service && SERVICES_LOGIN_URL_KEYS_MAP[this.service] && this.auto_connect) {
        if (this.service === SLACK_SERVICE && (!this.slack_visible || this.slackRedirectDisabled)) {
          return
        }
        this.authOpenNewWindow(this.service)
      } else {
        this.scrollToService()
      }
      if (this.service && this.success === '1') {
        this.addNotification({ type: 'success', body: `Your ${this.getServiceTitle} integration has been successfully completed.` })
      }
    })
    if (this.error_code) {
      let msg = ERROR_CODES[+this.error_code] ? ERROR_CODES[+this.error_code] : DEFAULT_ERROR_CODE_MSG
      msg = msg.replaceAll('{error_code}', this.error_code)
      this.addNotification({
        type: 'danger',
        body: msg
      })
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    ...mapActions('integrations', ['load_ad_accounts', 'delete_ad_account', 'updateAdAccountTimezone']),
    ...mapActions('notifications', ['addNotification']),
    ...mapActions('profile', ['getProfile']),
    ...mapActions('adService', ['setService']),

    deleteAccountAd (id: string): Promise<Response> {
      return new Promise((resolve, reject) => {
        this.delete_ad_account({ id: id, service: this.del_service })
          .then((response) => {
            this.adAccountId = null
            Object.keys(SERVICES_LOGIN_URL_KEYS_MAP).forEach((service) => this.load_ad_accounts({ service }))
            resolve(response)
          }).catch((error) => {
            reject(error)
          })
      })
    },
    emptyUrlNotification (service: string): void {
      this.addNotification({
        body: `Empty ${service} url, please contact support`,
        type: 'warning'
      })
    },
    authOpenNewWindow (service: string, pages = false): void {
      const service_login_url_key = SERVICES_LOGIN_URL_KEYS_MAP[service]
      const app_integrations_redirect_url: string | undefined = !pages
        ? (
          service !== SLACK_SERVICE
            ? process.env.VUE_APP_INTEGRATIONS_REDIRECT_URL as string | undefined
            : process.env.VUE_APP_INTEGRATIONS_SLACK_REDIRECT_URL as string | undefined
        )
        : process.env.VUE_APP_INTEGRATIONS_PAGES_REDIRECT_URL as string | undefined
      if (!service_login_url_key || !app_integrations_redirect_url) {
        return this.emptyUrlNotification(service)
      }
      const service_login_url = this.currentUser[service_login_url_key]
      if (!service_login_url) {
        return this.emptyUrlNotification(service)
      }
      const url = new URL(service_login_url)
      url.searchParams.append('redirect_frontend_url', app_integrations_redirect_url.replace(':service', service))
      window.location.assign(url.toString())
    },

    setConfirmDeleteModalText (service: string, adAccountName?: string) {
      const confirmDeleteText =
        SERVICES_MODAL_CONFIRM_DELETE_TEXT[service] ||
        SERVICES_MODAL_CONFIRM_DELETE_TEXT.default
      this.modalConfirmDeleteTitle = confirmDeleteText.titleCreator(adAccountName)
      this.modalConfirmDeleteText = confirmDeleteText.text
    },

    confirmDelete (service: {id: string, key: string, name: string}): void {
      this.setConfirmDeleteModalText(service.key, service.name)
      this.adAccountId = service.id
      this.adAccountName = service.name
      this.del_service = service.key
      this.$modal.show(ModalConfirmDelete, {
        params: { service: service },
        title: this.modalConfirmDeleteTitle,
        text: this.modalConfirmDeleteText,
        delete: this.deletionConfirmed
      }, {
        shiftY: 0.2,
        name: 'delete-campaign-modal',
        classes: 'ModalConfirmDelete',
        overlayTransition: 'linear',
        height: 'auto',
        width: '700',
        adaptive: true
      })
    },

    deletionConfirmed ({ service: { key } }): void {
      this.deleteAccountAd(this.adAccountId).then(() => {
        this.$modal.hide('delete-campaign-modal')
        this.getProfile()
        key === FACEBOOK_SERVICE && (this.servicePagesKey += 1)
      }).catch(() => {
        this.addNotification({ type: 'danger', body: 'Oops! Something went wrong! Please try later' })
      })
    },
    getUserAccountsTimezone ({ timezone }: { timezone: Timezone['id'] }): Timezone | null {
      return TIMEZONES.find(_ => _.id === timezone) || null
    },
    changeTimezone (user: { id: string }, timezone: Timezone, service: string): void {
      this.timezoneConfirmDisabled = false
      this.newTimezone = timezone
      this.newTimezoneUser = user
      this.newTimezoneService = service
      setTimeout(() => this.$modal.show('timezone-confirm'))
    },
    confirmTimezone (user: { id: string }, timezone: Timezone, service): void {
      if (!timezone || !user) return
      this.timezoneConfirmDisabled = true
      this.updateAdAccountTimezone({
        service,
        userId: user.id,
        timezoneId: timezone.id
      })
        .then(() => {
          this.$modal.hide('timezone-confirm')
        })
        .finally(() => {
          this.timezoneConfirmDisabled = false
        })
    },
    serviceScrollKey (key: string): string {
      return SERVICES_SCROLL_KEYS_MAP[key]
    },
    scrollToService (): void {
      if (this.scroll_to && this.serviceScrollKey(this.scroll_to)) {
        scrollHelper.scrollToElementWithSidebar(this.serviceScrollKey(this.scroll_to))
      }
    },
    onAccountStatusToggled (account: IListIntegrationAccount) {
      // update pages list if account disabled. Maybe not the best solution
      !account.is_enabled && (this.servicePagesKey += 1)
    }
  }
})
