












































































import CirclePreloader from '@/components/BaseCirclePreloader.vue'
import Switcher from '@/components/Switcher.vue'
import { IStateIntegrationDataWithPages } from '@/models/Integrations/IStateIntegrations'
import Vue, { PropType } from 'vue'
import { mapActions } from 'vuex'

interface IIntegrationPage {
  title: string,
  subtitle: string,
  service: string,
  state: IStateIntegrationDataWithPages
}

export default Vue.extend({
  name: 'ServicePages',
  components: {
    Switcher,
    CirclePreloader
  },
  props: {
    options: {
      type: Object as PropType<IIntegrationPage>,
      required: true
    }
  },
  computed: {
    pages () {
      return this.options.state.pages
    }
  },
  methods: {
    ...mapActions('integrations', ['getServicePages', 'toggleServicePage']),
    loadPages () {
      this.getServicePages(this.options.service)
    }
  },
  mounted () {
    this.loadPages()
  }
})

